.footer-grid {
  grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
}

.footer-link {
  transition: color .2s cubic-bezier(.4, 0, 1, 1);

  &::after {
    background-color: currentColor;
    bottom: -2px;
    content: "";
    display: inline-block;
    height: 1px;
    left: 0;
    opacity: 0;
    position: absolute;
    transition: opacity .2s cubic-bezier(.4, 0, 1, 1);
    width: 100%;
  }

  &:hover::after {
    opacity: 1;
  }
}